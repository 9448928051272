import { Component, Input,EventEmitter,Output, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { TranslatePipe } from 'sizing-shared-lib';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';


@Component({
  selector: 'app-process-conditions',
  templateUrl: './process-conditions.component.html',
  styleUrls: [ './process-conditions.component.scss' ]
})
export class ProcessConditionsComponent implements OnInit {
  @Input() moduleGroupId: number;
  @Input() form: FormGroup;
  @Output() isVolFlowMaster = new EventEmitter<boolean>();
  @Output() processConditionsChanged = new EventEmitter<boolean>();


  updateProcessConditions() {

    this.processConditionsChanged.emit(true);

  }

  public massFlowBefore: number;
  public massFlowAfter: number;
  public volumetricFlowBefore: number;
  public volumetricFlowAfter: number;
  // @BlockUI('process-conditions-section') blockUi: NgBlockUI;
  // private blockUiTimeout;

  get inletPressure() {
    return this.form.get('processConditions.inletPressure');
  }

  get inletPressureErrors() {
    if (this.form.get('processConditions').hasError('InletPressure')) {
      return this.translateErrors(this.form.get('processConditions').getError('InletPressure'));
    }

    if (this.inletPressure.hasError('InletPressure')) {
      return this.translateErrors(this.inletPressure.getError('InletPressure'));
    }

    if (this.form.get('inputErrors.inletPressureError').value) {
      debugger;
      var message = this.form.get('inputErrors.inletPressureError').value.slice(0, this.form.get('inputErrors.inletPressureError').value.lastIndexOf("("));
      var validValue = this.form.get('inputErrors.inletPressureError').value.slice(this.form.get('inputErrors.inletPressureError').value.lastIndexOf("("));
      if (validValue.includes("125")) {
        validValue = validValue.slice(validValue.lastIndexOf("(")+1)
      }
      return this.translateValidationErrors(message) + validValue;
    }

    return null;
  }

  get temperature() {
    return this.form.get('processConditions.temperature');
  }

  get temperatureErrors() {
    if (this.form.get('processConditions').hasError('InletTemperature')) {
      return this.translateErrors(this.form.get('processConditions').getError('InletTemperature'));
    }

    if (this.temperature.hasError('InletTemperature')) {
      return this.translateErrors(this.temperature.getError('InletTemperature'));
    }

    if (this.form.get('inputErrors.temperatureError').value) {
      var message = this.form.get('inputErrors.temperatureError').value.slice(0, this.form.get('inputErrors.temperatureError').value.lastIndexOf("("));
      var validValue = this.form.get('inputErrors.temperatureError').value.slice(this.form.get('inputErrors.temperatureError').value.lastIndexOf("("));
      return this.translateValidationErrors(message) + validValue;
    }

    return null;
  }

  get massFlow() {
    return this.form.get('processConditions.massFlow');
  }

  get massFlowErrors() {   

    if (this.form.get('processConditions').hasError('MassFlow')) {
      return this.translateErrors(this.form.get('processConditions').getError('MassFlow'));
    }

    if (this.massFlow.hasError('MassFlow')) {
      return this.translateErrors(this.massFlow.getError('MassFlow'));
    }

    if (this.form.get('inputErrors.massFlowError').value) {
      var message = this.form.get('inputErrors.massFlowError').value.slice(0, this.form.get('inputErrors.massFlowError').value.lastIndexOf("("));
      var validValue = this.form.get('inputErrors.massFlowError').value.slice(this.form.get('inputErrors.massFlowError').value.lastIndexOf("("));
      return this.translateValidationErrors(message) + validValue;
    }

    return null;
  }

  get volumetricFlow() {
    return this.form.get('processConditions.volumetricFlow');
  }

  get volumetricFlowErrors() {
    if (this.form.get('processConditions').hasError('VolumetricFlow')) {
      return this.translateErrors(this.form.get('processConditions').getError('VolumetricFlow'));
    }

    if (this.volumetricFlow.hasError('VolumetricFlow')) {
      return this.translateErrors(this.volumetricFlow.getError('VolumetricFlow'));
    }
    
    if (this.form.get('inputErrors.volumetricFlowError').value) {
      var message = this.form.get('inputErrors.volumetricFlowError').value.slice(0, this.form.get('inputErrors.volumetricFlowError').value.lastIndexOf("("));
      var validValue = this.form.get('inputErrors.volumetricFlowError').value.slice(this.form.get('inputErrors.volumetricFlowError').value.lastIndexOf("("));
      return this.translateValidationErrors(message) + validValue;
    }

    return null;
  }

  get normalTemperature() {
    return this.form.get('processConditions.normalTemperature');
  }

  get normalTemperatureErrors() {
    if (this.form.get('processConditions').hasError('NormalTemperature')) {
      return this.translateErrors(this.form.get('processConditions').getError('NormalTemperature'));
    }

    if (this.normalTemperature.hasError('NormalTemperature')) {
      return this.translateErrors(this.normalTemperature.getError('NormalTemperature'));
    }

    if (this.form.get('inputErrors.normalTemperatureError').value) {
      var message = this.form.get('inputErrors.normalTemperatureError').value.slice(0, this.form.get('inputErrors.normalTemperatureError').value.lastIndexOf("("));
      var validValue = this.form.get('inputErrors.normalTemperatureError').value.slice(this.form.get('inputErrors.normalTemperatureError').value.lastIndexOf("("));
      return this.translateValidationErrors(message) + validValue;
    }

    return null;
  }

  private translateErrors(errors: string[]): string {
    const translated = errors.map(error => this.translatePipe.transform(error));
    return translated.join('\n');
  }

  private translateValidationErrors(error: string) {
    const translated = this.translatePipe.transform(error);
    return translated;
  }

  constructor(
    private router: Router,
    public translatePipe: TranslatePipe,
  ) {
  }

  ngOnInit() {
  }

  LastCapacityEntryPoint(value: boolean) {
    this.isVolFlowMaster.emit(value);
  }

  massFlowOnFocusOut() {
    try {
      this.massFlowAfter = this.form.get('processConditions.massFlow').value;

      setTimeout(() => {
        this.volumetricFlowBefore = this.form.get('processConditions.volumetricFlow').value;
      }, 1000);

    }
    catch {
      //nothing to catch here :)
    }
    finally {
      if (this.massFlowBefore != this.massFlowAfter) {
        this.isVolFlowMaster.emit(false);
      }
    }
  }

  volumetricFlowOnFocusOut() {
    try {
      this.volumetricFlowAfter = this.form.get('processConditions.volumetricFlow').value;

      setTimeout(() => {
        this.massFlowBefore = this.form.get('processConditions.massFlow').value;
      }, 1000);

    }
    catch {
      //nothing to catch here :)
    }
    finally {
      if (this.volumetricFlowBefore != this.volumetricFlowAfter) {
        this.isVolFlowMaster.emit(true);
      }
    }
  }
  onEnterKeydown(event) {
    event.preventDefault();
    //document.getElementById(event.srcElement.id).blur();
   
  }

  // Developer quick sizing
  quickSizing(metric: boolean = true) {

    var baseURL = this.router['location']._platformLocation.location.origin;

    console.log('baseURL = "' + baseURL + '"');

    if (baseURL === 'https://prsapps.spiraxsarco.com') {
      // disable feature on the live web server implementation
      return;
    }

    if (metric) {
      this.form.get('processConditions.inletPressure').setValue(5, { emitEvent: false });
      this.form.get('processConditions.massFlow').setValue(555, { emitEvent: false });
      this.form.get('meterDetails.selectedMeters').setValue(['TFA', 'TVA']);
    }
    else {

    }
    // this.onCalculateSizing(this.sizingModuleForm); not accessable, manual click for calc!
  }
}
