import { Component, OnInit, Input, Output, EventEmitter ,ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';
import { IGenericChanges } from '../../../../sizing-shared-lib/src/lib/modules/generic.changes.interface';
import { FormGroupConfig } from './../models/form-groups/FormGroupConfiguration'
import { ProcessConditionsFormGroup } from './../models/form-groups/ProcessConditionsFormGroup'
import { UnitType } from './../models/UnitType'
import { DisplayPreferenceDirective, Preference, UnitsService, User, TranslationService, EnumerationComponent, UnitConvert, UnitsConverter } from 'sizing-shared-lib'
import { validateInletPressure, validateTotalBackPressure, validateMotiveInletPressure, validateInletTemperature, validateOutletTemperature, validateWaterFlowRate, validateLoadInput, hotwellTemperatureValidation } from '../validators/userInputValidators.component';
import { EasiHeatGen4Service } from '../services/easiheatgen4.services'
import { EasiheatGen4FlowOrLoad, EasiheatGen4ValidationErrorMessage } from '../models/inputValidation.model';
import { TranslatePipe } from "sizing-shared-lib";
import { isNull, isNullOrUndefined, isUndefined } from 'util';
import { ModulePreferenceModel } from '../models/ModulePreferencesModel';

@Component({
  selector: 'app-easiheat-process-conditions',
  templateUrl: './easiheat-process-conditions.component.html',
  styleUrls: ['./easiheat-process-conditions.component.scss'],
  providers: []
})
export class EasiheatProcessConditionsComponent implements OnInit {

  processConditionsFormGroup: FormGroup;
  hasPrefs: boolean = true;
  //public dhw: boolean = true;
  //public htgcc: boolean = false;
  public cvSplitRangeAvailable: boolean;
  public motiveInletPressureAvailable: boolean;
  public allowHighDesignPressure: boolean;

  public pressure_Drop_Enumeration_Name: string;
  public pressure_Drop_Unit: string;
  public pressureItemIndex: number;
  public motiveInletPressureEntered: boolean = false;
  public globalVariance: string;
  public PrimarySideMaxDesignPressure: number;
  public PrimarySideMaxOperatingPressure: number;
  globalVName: string;
  maxPressureDropFilterList: string[] = [];

  currencySymbol: string[] = [];
  hasCurrencySymbol = false;

  //public unitType = {} as UnitType;
  inletPressureValidationErrorMessage: EasiheatGen4ValidationErrorMessage;
  @Input() pressureUnit: DisplayPreferenceDirective;
  @Input() temperatureUnit: DisplayPreferenceDirective;
  @Input() volumetricFlowUnit: DisplayPreferenceDirective;
  @Input() loadUnit: DisplayPreferenceDirective;
  @Input() emissionUnit: DisplayPreferenceDirective;
  @Input() energyUnit: DisplayPreferenceDirective;
  @Input() massFlowUnit: DisplayPreferenceDirective;
  @Input() volumeUnit: DisplayPreferenceDirective;
  @Input() userPrefs: Preference[];
  @Input() unitType = { dhw: true, htgcc: false } as UnitType;
  @Input() modPref: ModulePreferenceModel;

  @Output() unitTypeChange = new EventEmitter<UnitType>();
  @Output() processConditionsChanged = new EventEmitter();

  public user: User;

  constructor(
    private fb: FormBuilder,
    private apiService: EasiHeatGen4Service,
    private translatePipe: TranslatePipe,
    private unitsService: UnitsService,
    private translationService: TranslationService

  ) {

    this.setGlobalVarianceUnits(this.translationService.displayGroup.enumerations.filter(mp => mp.opCoOverride === true && mp.enumerationName === "EH_GEN4_GlobalVariance")[0].enumerationDefinitions[0].value.toUpperCase());
  
  }

  ngOnInit() {
    this.modPref;
    this.setUiFieldsVisibility();
    this.globalVName = 'EH_GEN4_GlobalVariance';

    this.pressureUnit.preference.masterTextKey;
    this.setHotwellTemperature();

  }

  setDefaults(event: any) {

    this.processConditionsFormGroup.get('globalVariance').patchValue(event.selectedValue);
  }

  setGlobalVarianceUnits(value: string) {

    this.globalVariance = value;
    if (value !== 'AS/UL') {
      this.pressure_Drop_Enumeration_Name = "DHW_PressureDrop_EasiHeat_Gen4";
      this.pressure_Drop_Unit = "KPA"
      this.pressureItemIndex = 4;
      this.setPrimarySideMaxPressures();

    }
    else {
      this.pressure_Drop_Enumeration_Name = "PressureDropPsiRounded_EasiHeat";
      this.pressure_Drop_Unit = "PSI"
      this.pressureItemIndex = 4;
      this.setPrimarySideMaxPressures();


    }
  }

  setMaxPressDropValue(event: any) {
    this.processConditionsFormGroup.get('maximumPressureDrop').patchValue(event.selectedValue);
  }

  setEnergySavingFuelType(event: any) {
    this.processConditionsFormGroup.get('fuelType').patchValue(event.selectedValue);
  }

  setDefaultEnergySavingFuelType(event: any) {
    this.processConditionsFormGroup.get('fuelType').patchValue('G-Natural Gas');
  }


  selectedUnitType() {

    this.processConditionsFormGroup.get('inletPressure').updateValueAndValidity(); 
    this.clearTemperaturesErrors();
    if (this.globalVariance !== 'AS/UL') {
      if (this.unitType.dhw) {
        this.pressure_Drop_Enumeration_Name = "DHW_PressureDrop_EasiHeat_Gen4";
        this.pressureItemIndex = 4;
        this.processConditionsFormGroup.get('maximumPressureDrop').patchValue('25');
      }
      else {
        this.pressure_Drop_Enumeration_Name = "DHW_PressureDrop_EasiHeat_Gen4";;
        this.pressureItemIndex = 9;
        this.processConditionsFormGroup.get('maximumPressureDrop').patchValue('50');
      }
    }
    else {
      this.pressureItemIndex = 4;
    }
    this.unitTypeChange.emit(this.unitType)
  }

  setUiFieldsVisibility() {
    this.cvSplitRangeAvailable = this.modPref.EHgen4_CVSplitRange;
    this.motiveInletPressureAvailable = this.modPref.EHgen4_MotiveInletPressureAvailable;
    this.allowHighDesignPressure = this.modPref.EHgen4_HEAllowHighDesignPressure;
  }

  clearTemperaturesErrors() {
    this.processConditionsFormGroup.get('inletTemperature').setErrors(null);
    this.processConditionsFormGroup.get('outletTemperature').setErrors(null);
  }

  createFormGroup() {
    const config: FormGroupConfig<ProcessConditionsFormGroup> = {
      inletPressure: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      motiveInletPressure: [null, {
        validators: [Validators.nullValidator],
        updateOn: 'blur'
      }],
      totalBackPressure: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      noiseLimit: ['85', {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }],
      sizeCvSplitRangeOnly: [false],
      inletTemperature: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      outletTemperature: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      waterFlowRate: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      load: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      maximumPressureDrop: [null, {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }],
      globalVariance: [null, {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }],
      designPressure12Bar: [false, {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }],
      performEnergySavingCalc: [
        false, { updateOn: 'change' }
      ],
      hoursOfOperation: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(1), Validators.max(8760)]
      ],
      diversityFactor: [
        { value: 1, disabled: true },
        [Validators.required, Validators.min(0.1), Validators.max(1)]
      ],
      fuelType: [null, {
        validators: [Validators.nullValidator],
        updateOn: 'change'
      }],
      costOfCarbon: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(0), Validators.max(999999999999999)]
      ],
      calorificValue: [
        { value: null, disabled: true }
      ],
      emissionValueCO2e: [
        { value: null, disabled: true }
      ],
      energyUnitCost: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(0), Validators.max(999999999999999)]
      ],
      waterUnitCost: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(0), Validators.max(999999999999999)]
      ],
      waterTreatmentUnitCost: [
        { value: null, disabled: true },
        [Validators.required, Validators.min(0), Validators.max(999999999999999)]
      ],
      hotwellTemperature: [null, {
        validators: [Validators.required],
        updateOn: 'blur'
      }],
      boilerEfficiency: [
        { value: 85, disabled: true },
        [Validators.required, Validators.min(25), Validators.max(100)]
      ],
      boilerPressure: [{ value: null, disabled: true }],

    }

    this.processConditionsFormGroup = this.fb.group(config); 

    this.processConditionsFormGroup.get('inletPressure').valueChanges.subscribe(() => this.onInletPressureChanged());
    this.processConditionsFormGroup.get('totalBackPressure').valueChanges.subscribe(() => this.onTotalBackPressureChanged());
    this.processConditionsFormGroup.get('motiveInletPressure').valueChanges.subscribe((val) => this.onMotiveInletPressureChanged(val));
    this.processConditionsFormGroup.get('inletTemperature').valueChanges.subscribe(() => this.onInletTemperatureChanged());
    this.processConditionsFormGroup.get('outletTemperature').valueChanges.subscribe(() => this.onOutletTemperatureChanged());
    this.processConditionsFormGroup.get('waterFlowRate').valueChanges.subscribe((val) => this.onWaterFlowRateChanged(val));
    this.processConditionsFormGroup.get('load').valueChanges.subscribe((val) => this.onLoadChanged(val));
    this.processConditionsFormGroup.get('sizeCvSplitRangeOnly').valueChanges.subscribe((val) => this.onCvSplitRangeChange(val));
    this.processConditionsFormGroup.get('globalVariance').valueChanges.subscribe((val) => this.onGlobalVarianceChange(val));
    this.processConditionsFormGroup.get('designPressure12Bar').valueChanges.subscribe(() => this.onDesignPressure12BarChange());
    this.processConditionsFormGroup.get('performEnergySavingCalc').valueChanges.subscribe((val) => this.onPerformEnergySavingCalcChange(val));
    this.processConditionsFormGroup.get('hotwellTemperature').valueChanges.subscribe((val) => this.onHotwellTemperatureChange(val));
    this.processConditionsFormGroup.valueChanges.subscribe(() => this.onProcessConditionsChange());

    return this.processConditionsFormGroup;
  }

  getGlobalVariance(): string {
    return this.globalVariance;
  }

  resetProcessConditionsFormGroup() {
    this.processConditionsFormGroup.reset();
    this.processConditionsFormGroup.get('sizeCvSplitRangeOnly').setValue(false, { emitEvent: false });
  }

  getFormInformation(): ProcessConditionsFormGroup {
    return this.processConditionsFormGroup.getRawValue();
  }

  onProcessConditionsChange() {
    this.processConditionsChanged.emit();
  }

  onInletPressureChanged() {
    validateInletPressure(this.processConditionsFormGroup, this.apiService, this.pressureUnit, this.unitType, this.translatePipe);
 
  };

  onTotalBackPressureChanged() {
    validateTotalBackPressure(this.processConditionsFormGroup, this.apiService, this.pressureUnit, this.unitType, this.translatePipe);
  };

  onMotiveInletPressureChanged(val) {
    validateMotiveInletPressure(this.processConditionsFormGroup, this.apiService, this.pressureUnit, this.translatePipe);
    if (val != '' && !isNull(val)) {
      this.motiveInletPressureEntered = true;
    }
    else {
      this.motiveInletPressureEntered = false;
    }
  };

  onInletTemperatureChanged() {
    validateInletTemperature(this.processConditionsFormGroup, this.apiService, this.temperatureUnit, this.unitType, this.translatePipe);
    this.recalcLoadAndFlowRate();
  };

  onOutletTemperatureChanged() {
    validateOutletTemperature(this.processConditionsFormGroup, this.apiService, this.temperatureUnit, this.unitType, this.translatePipe);
    this.recalcLoadAndFlowRate();
  };

  onWaterFlowRateChanged(val) {
    validateWaterFlowRate(this.processConditionsFormGroup, this.apiService, this.volumetricFlowUnit, this.temperatureUnit, this.translatePipe);
    this.processConditionsFormGroup.get('load').markAsPristine();
    this.CalcLoad(val);
  };

  onLoadChanged(value: number) {
    validateLoadInput(this.processConditionsFormGroup, this.apiService, this.loadUnit, this.translatePipe);
    this.processConditionsFormGroup.get('waterFlowRate').markAsPristine();
    this.CalcWaterFlowRate(value);
  };

  onCvSplitRangeChange(val) {
    console.log(val);
  }

  onGlobalVarianceChange(val) {
    this.setGlobalVarianceUnits(val);
    this.processConditionsFormGroup.get('globalVariance').patchValue(val, { emitEvent: false });
  }

  onDesignPressure12BarChange() {
    this.setPrimarySideMaxPressures();
    
    if (this.processConditionsFormGroup.get('inletPressure').value != null && this.processConditionsFormGroup.get('inletPressure').value != '') {
      this.processConditionsFormGroup.get('inletPressure').setErrors(null);
      setTimeout(() => { this.onInletPressureChanged(); }, 600);
    }
  }

  onHotwellTemperatureChange(val) {
    hotwellTemperatureValidation(this.processConditionsFormGroup, this.unitsService, val, this.temperatureUnit, this.translatePipe);
  }

  onPerformEnergySavingCalcChange(val) {
    const hoursOfOperationControl = this.processConditionsFormGroup.get('hoursOfOperation');
    const diversityFactorControl = this.processConditionsFormGroup.get('diversityFactor');
    const fuelTypeControl = this.processConditionsFormGroup.get('fuelType');
    const costOfCarbonControl = this.processConditionsFormGroup.get('costOfCarbon');
    const calorificValueControl = this.processConditionsFormGroup.get('calorificValue');
    const emissionValueCO2eControl = this.processConditionsFormGroup.get('emissionValueCO2e');
    const energyUnitCostControl = this.processConditionsFormGroup.get('energyUnitCost');
    const waterUnitCostControl = this.processConditionsFormGroup.get('waterUnitCost');
    const waterTreatmentUnitCostControl = this.processConditionsFormGroup.get('waterTreatmentUnitCost');
    const hotwellTemperatureControl = this.processConditionsFormGroup.get('hotwellTemperature');
    const boilerEfficiencyControl = this.processConditionsFormGroup.get('boilerEfficiency');
    const boilerPressureControl = this.processConditionsFormGroup.get('boilerPressure');

    if (val) {
      if (hoursOfOperationControl && hoursOfOperationControl.disabled) { hoursOfOperationControl.enable(); }
      if (diversityFactorControl && diversityFactorControl.disabled) { diversityFactorControl.enable(); }
      if (fuelTypeControl && fuelTypeControl.disabled) { fuelTypeControl.enable(); fuelTypeControl.patchValue('G-Natural Gas') }
      if (costOfCarbonControl && costOfCarbonControl.disabled) { costOfCarbonControl.enable(); }
      if (energyUnitCostControl && energyUnitCostControl.disabled) { energyUnitCostControl.enable(); }
      if (waterUnitCostControl && waterUnitCostControl.disabled) { waterUnitCostControl.enable(); }
      if (waterTreatmentUnitCostControl && waterTreatmentUnitCostControl.disabled) { waterTreatmentUnitCostControl.enable(); }
      if (hotwellTemperatureControl && hotwellTemperatureControl.disabled) { hotwellTemperatureControl.enable(); }
      if (boilerEfficiencyControl && boilerEfficiencyControl.disabled) { boilerEfficiencyControl.enable(); }

      if (boilerPressureControl && boilerPressureControl.disabled) { boilerPressureControl.enable();
        if (isNull(boilerPressureControl.value)) {
          boilerPressureControl.patchValue(this.processConditionsFormGroup.get('inletPressure').value);
        }
      }

      this.processConditionsFormGroup.get('calorificValue').patchValue(25);
      this.processConditionsFormGroup.get('emissionValueCO2e').patchValue(25);
      
    }
    else {
      if (hoursOfOperationControl && hoursOfOperationControl.enabled) { hoursOfOperationControl.disable(); }
      if (diversityFactorControl && diversityFactorControl.enabled) { diversityFactorControl.disable(); }
      if (fuelTypeControl && fuelTypeControl.enabled) { fuelTypeControl.disable(); }
      if (costOfCarbonControl && costOfCarbonControl.enabled) { costOfCarbonControl.disable(); }
      if (energyUnitCostControl && energyUnitCostControl.enabled) { energyUnitCostControl.disable(); }
      if (waterUnitCostControl && waterUnitCostControl.enabled) { waterUnitCostControl.disable(); }
      if (waterTreatmentUnitCostControl && waterTreatmentUnitCostControl.enabled) { waterTreatmentUnitCostControl.disable(); }
      if (hotwellTemperatureControl && hotwellTemperatureControl.enabled) { hotwellTemperatureControl.disable(); }
      if (boilerEfficiencyControl && boilerEfficiencyControl.enabled) { boilerEfficiencyControl.disable(); }
      if (boilerPressureControl && boilerPressureControl.enabled) { boilerPressureControl.disable(); }
    }
  }



  /// <summary>
  /// calculates Water Flow Rate based on Load
  /// </summary>
  CalcWaterFlowRate(loadValue: number) {

    console.log('Calculate Water Flow Rate');

    let inTemp = this.processConditionsFormGroup.get('inletTemperature').value;
    let outTemp = this.processConditionsFormGroup.get('outletTemperature').value;

    if (loadValue !== null) {
     
      // check if both temperatures have been entered and if load is entered and valid
      if (inTemp && outTemp && this.temperatureUnit != null && this.volumetricFlowUnit != null) {

        // Add details into the calculation model.
        var ehFlowOrLoad: EasiheatGen4FlowOrLoad = new EasiheatGen4FlowOrLoad();

        ehFlowOrLoad.inletTemperature = +inTemp;
        ehFlowOrLoad.outletTemperature = +outTemp;
        ehFlowOrLoad.temperatureUnits = +this.temperatureUnit.preference.value;
        ehFlowOrLoad.value = +loadValue;
        ehFlowOrLoad.flowUnits = +this.volumetricFlowUnit.preference.value;
        ehFlowOrLoad.loadUnits = +this.loadUnit.preference.value;

        this.apiService.calculateVolumetricFlow(ehFlowOrLoad).subscribe((result: number) => {
          // Check if there's any validation errors? If so, set form control and error message accordingly.
          if (!isNullOrUndefined(result)) {

            //calculated volumetric flow value
            console.log("calculated volumetric flow is : " + result.toString());
            if (result > -1) {
              var decimalPlaces = this.userPrefs.find(m => m.name === "VolumetricFlowUnit").decimalPlaces;
              var tempWaterFlow = parseFloat(result.toFixed(decimalPlaces));

              this.processConditionsFormGroup.get('waterFlowRate').patchValue(tempWaterFlow, {emitEvent : false});
              this.processConditionsFormGroup.get('waterFlowRate').setErrors(null);
            }
          }
        });
      }
    }
    return null
  }

  /// <summary>
  /// calculates Load based on Water Flow Rate
  /// </summary>
  CalcLoad(waterFlowValue: number) {

    console.log('Calculate Load');

    let inTemp = this.processConditionsFormGroup.get('inletTemperature').value;
    let outTemp = this.processConditionsFormGroup.get('outletTemperature').value;


    if (waterFlowValue !== null) {

      // check if both temperatures have been entered and if load is entered and valid
      if (inTemp && outTemp && this.temperatureUnit != null && this.loadUnit != null) {

        // Add details into the calculation model.
        var ehFlowOrLoad: EasiheatGen4FlowOrLoad = new EasiheatGen4FlowOrLoad();

        ehFlowOrLoad.inletTemperature = +inTemp;
        ehFlowOrLoad.outletTemperature = +outTemp;
        ehFlowOrLoad.temperatureUnits = +this.temperatureUnit.preference.value;
        ehFlowOrLoad.value = +waterFlowValue;
        ehFlowOrLoad.flowUnits = +this.volumetricFlowUnit.preference.value;
        ehFlowOrLoad.loadUnits = +this.loadUnit.preference.value;

        this.apiService.calculateLoad(ehFlowOrLoad).subscribe((result: number) => {
          // Check if there's any validation errors? If so, set form control and error message accordingly.
          if (!isNullOrUndefined(result)) {

            //calculated volumetric flow value
            console.log("calculated Load is : " + result.toString());

            // No longer validating a calculated value so need to do decimal places here as well
            var decimalPlaces = this.userPrefs.find(m => m.name === "LoadUnit").decimalPlaces;
            var tempLoad = parseFloat(result.toFixed(decimalPlaces));

            // Don't validate a calculated field so turn off validators before setting value. This is as per V1. Can be reviewed for Gen 4.
            //this.sizingModuleForm.get('load').setValidators([Validators.required]);
            this.processConditionsFormGroup.get('load').patchValue(tempLoad, {emitEvent : false});
            //this.load.updateValueAndValidity();
            //this.sizingModuleForm.get('load').setValidators([Validators.required, (c) => this.validateLoadInput(c, this.loadRef, this.loadValidationErrorMessage), (c) => this.CalcWaterFlowRate(c), (c) => this.validateProcessConditions(c, 'InputLoad', this.loadRef, this.validateProcessConditionsErrorMessage)]);
            this.processConditionsFormGroup.get('load').setErrors(null);
            //this.validateWaterFlowRate(this.waterflowrate, this.volumetricFlowRef, this.temperatureRef, this.waterFlowRateValidationErrorMessage);

            //this.loadBefore = this.load.value;
            //this.sizingModuleForm.get('waterflowrate').setValidators([Validators.required, (c) => this.validateWaterFlowRate(c, this.volumetricFlowRef, this.temperatureRef, this.waterFlowRateValidationErrorMessage), (c) => this.CalcLoad(c), (c) => this.validateProcessConditions(c, 'InputFlowRate', this.volumetricFlowRef, this.validateProcessConditionsErrorMessage)]);

          }
        });
      }
    }
    return null
  }

  //this method is called when user switch between DHW and HTG options 
  recalcLoadAndFlowRate() {
    let load = this.processConditionsFormGroup.get('load').dirty;
    let flow = this.processConditionsFormGroup.get('waterFlowRate').dirty;

    if (load) {
      this.CalcWaterFlowRate(this.processConditionsFormGroup.get('load').value);
    }
    if (flow) {
      this.CalcLoad(this.processConditionsFormGroup.get('waterFlowRate').value);
    }
  }

  setHotwellTemperature() {

    let initialHotwellTemperature: number;

    const unitsToConvert: UnitConvert[] = [];
    unitsToConvert.push({
      propertyName: 'initialHotwellTemperature',
      initialValue: 85,
      initialUnitId: 146,
      targetUnitId: +this.temperatureUnit.preference.value,
      convertedValue: null,
    });

    const unitsConverter: UnitsConverter = {
      unitsConverter: unitsToConvert
    };

    this.unitsService.unitsConverter(unitsConverter).subscribe((unitsConvertedData: UnitsConverter) => {

      if (unitsConvertedData) {
        initialHotwellTemperature = +(unitsConvertedData.unitsConverter.find(u => u.propertyName === 'initialHotwellTemperature').convertedValue.toFixed());
        this.processConditionsFormGroup.get('hotwellTemperature').patchValue(initialHotwellTemperature, { emitEvent: false });
      }
    })
  }

  setPrimarySideMaxPressures() {

    if (!isNullOrUndefined(this.processConditionsFormGroup)) {
      if (!isNullOrUndefined(this.processConditionsFormGroup.get('designPressure12Bar').value)) {

        if (this.processConditionsFormGroup.get('designPressure12Bar').value) {
          this.PrimarySideMaxDesignPressure = 12;
        }
        else {
          this.PrimarySideMaxDesignPressure = 9;
        }
        this.PrimarySideMaxOperatingPressure = 9;


        if (this.globalVariance == 'AS/UL') {

          const unitsToConvert: UnitConvert[] = [];
          unitsToConvert.push({
            propertyName: 'PrimarySideMaxDesignPressure',
            initialValue: this.PrimarySideMaxDesignPressure,
            initialUnitId: 50,// bar gauge
            targetUnitId: 58,// psi gauge
            convertedValue: null,
          });

          unitsToConvert.push({
            propertyName: 'PrimarySideMaxOperatingPressure',
            initialValue: 9,
            initialUnitId: 50, // bar gauge
            targetUnitId: 58, // psi gauge
            convertedValue: null,
          });

          const unitsConverter: UnitsConverter = {
            unitsConverter: unitsToConvert
          };

          this.unitsService.unitsConverter(unitsConverter).subscribe((unitsConvertedData: UnitsConverter) => {

            if (unitsConvertedData) {
              this.PrimarySideMaxDesignPressure = Math.trunc(unitsConvertedData.unitsConverter.find(u => u.propertyName === 'PrimarySideMaxDesignPressure').convertedValue);
              this.PrimarySideMaxOperatingPressure = Math.trunc(unitsConvertedData.unitsConverter.find(u => u.propertyName === 'PrimarySideMaxOperatingPressure').convertedValue);
            }
          })

        }
      }
    }
    
  }



  setCurrencySymbol(symbol: string) {
    this.currencySymbol[0] = symbol;
    this.hasCurrencySymbol = true;
  }
  //this is called only when page reset butten is clicked
  setEnergySavingDefaults() {
    this.setHotwellTemperature();
    this.processConditionsFormGroup.get('boilerEfficiency').patchValue(85, { emitEvent: false });
    this.processConditionsFormGroup.get('diversityFactor').patchValue(1, { emitEvent: false });
  }
}
