export class EHSizingDataInput {

  /**
  * Gets or sets
  */
  UserId: number;
  /**
  * Gets or sets 
  */
  UserName: string;
  /**
  * Gets or sets 
  */
  UserCountry: number;
  /**
  * Gets or sets 
  */
  UserOperatorType: string;
  /**
  * Gets or sets 
  */
  UserSellingCurrency: number;
  /**
  * Gets or sets 
  */
  UserManufacturerCurrency: number;
  /**
  * Application Type DHW, HTGSC or HTGCC.
  * Gets or sets 
  */
  ApplicationType: string;

  //PricingApplicationType: string;__________?

  /**
  * Gets or sets 
  */
  InletPressure: number;
  /**
  * Gets or sets 
  */
  InletPressureUnit: number;
  /**
  * Gets or sets 
  */
  BackPressure: number;
  /**
  * Gets or sets 
  */
  DesignPressure12Bar : boolean;
  /**
  * Gets or sets 
  */
  MotivePressureAvailable: boolean;
  /**
  * Gets or sets 
  */
  MotivePressure: number;
  /**
  * Gets or sets 
  */
  InletTemperature: number;
  /**
  * Gets or sets 
  */
  OutletTemperature: number;
  /**
  * Gets or sets 
  */
  TemperatureUnit: number;
  /**
  * Gets or sets 
  */
  WaterFlowRate: number;
  /**
  * Gets or sets 
  */
  FlowRateUnit: number;

  /**
  * Load Value in User Units
  * Gets or sets 
  */
  HeLoad: number;

  /**
  * He Load user chosen Unit
  * Gets or sets 
  */
  HeLoadUnit: number;
  /**
  * Gets or sets 
  */
  NoiseLimit: number;
  /**
  * Gets or sets 
  */
  NoiseLimitUnit: number;
  /**
  * Gets or sets 
  */
  UserCondensateSet: boolean;
  /**
  * Gets or sets 
  */
  CondensateRemoval: string;
  /**
  * Gets or sets 
  */
  ServiceOffering: string;
  /**
  * Gets or sets 
  */
  DesignCode: string;
  /**
  * Gets or sets 
  */
  AllowSizeCvSplitRange: boolean;
  /**
 * Gets or sets 
 */
  SizeCvSplitRangeOnly: boolean;
  /**
  * Gets or sets 
  */
  Manufacturer: number;
  /**
  * Gets or sets 
  */
  MaxPressureDrop: number;
  /**
  * Gets or sets 
  */
  MaxPressureDropUnit: number;
  /**
  * Gets or sets 
  */
  HeOverriddenP251Markup: boolean;
  /**
  * Gets or sets 
  */
  HeOverriddenP251MarkupValue: number;
  /**
  * Gets or sets 
  */
  HeTs6Discount: number;
  /**
  * Gets or sets 
  */
  HeM6Discount: number;
  /**
  * Gets or sets 
  */
  HeM10Discount: number;
  /**
  * Gets or sets 
  */
  HeT6PDiscount: number;
  /**
  * Gets or sets 
  */
  HeT8MDiscount: number;
  /**
  * Gets or sets 
  */
  HeT10MDiscount: number;
  /**
  * Gets or sets 
  */
  SellingCurrencyConversionRate: number;
  /**
  * Gets or sets 
  */
  ManufacturingCurrencyConversionRate: number;
  /**
  * Gets or sets 
  */
  GasketCode: string;
  /**
  * Gets or sets 
  */
  GlobalVariance: string;
  /**
* Gets or sets Application Type for re-size when user select different Gasket from options list.
*/
  PricingApplicationtype: string;






}

