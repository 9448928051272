import { UnitOptionsFormGroup } from './form-groups/UnitOptionsFormGroup'
import { ProcessConditionsFormGroup } from './form-groups/ProcessConditionsFormGroup'
import { PricingOptionsFormGroup } from './form-groups/PricingOptionsFormGroup'
import { EasiHeatUnitFormGroup } from './form-groups/EasiHeatUnitFormGroup'
import { EnergySavingsFuelInfoInterface } from './energySavingFuelInfo.interface'
import { EHPricingOptions } from '../../../../easiheat-app/src/app/easiheatPricingOptions.model';
import { SpecSheetHeaderDetailsComponent } from '../../../../sizing-shared-lib/src/lib/shared/spec-sheet-header-details/spec-sheet-header-details.component';
import { HeaderDetails } from './specSheetHeaderDetails.interface.component';


export class SpecificationSheetValuesCollection {
  processConditions: ProcessConditionsFormGroup;
  selectedUnit: EasiHeatUnitFormGroup
  selectedUnitOptions: EHPricingOptions[]; 
  specSheetHeaders: HeaderDetails;
  energySavingFuelInfo: EnergySavingsFuelInfoInterface
}
