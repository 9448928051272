export class EnumerationNames{

  public name: string;
  public gasketMaterial: string[] = [];
  public globalVariance: string[] = [];
  public actuator: string[] = [];
  public condensateRemoval: string[] = [];
  public highLimit: string[] = [];
  public manualIsolation: string[] = [];
  public systemRecirculation: string[] = [];
  public controlSystem: string[] = [];
  public frameType: string[] = [];
  public packageSupports: string[] = [];
  public panelLocation: string[] = [];
  public extras: string[] = [];
  public integrityTest: string[] = [];
  public dataCollection: string[] = [];
  public communications: string[] = [];
  public service_Offering: string[] = [];
  public pressure_Drop: string[] = [];
  public controlValveFeedback: string[] = [];
  public fa11: string[] = [];
  public pa31: string[] = [];
  public ta11: string[] = [];
  public ta31: string[] = [];
  public ta41: string[] = [];
  public ta01: string[] = [];
  public ups: string[] = [];
  public insulation: string[] = [];
} 
