import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { SgaFormService } from '../../services/sga-form.service';
import { InputParametersTFormInterface, TForm, TFormValueGetterInterface } from '../../interfaces/forms.interface';
import { PreferenceService, UnitsService } from 'sizing-shared-lib';
import { FuelTypesEnumerationLetter } from '../../interfaces/fuel-type.interface';
import {
  SgaCalcBoilerEfficiencyReqInterface,
  SgaCalcCalorificReqInterface,
  SgaCalcWaterTreatmentReqInterface
} from '../../interfaces/api-requests.interface';
import { FormListFuelTypeChangeInterface } from '../../interfaces/e-emiters.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import sgaFormStructure from '../../utils/sga-form-structure';
import { SgFormStructureInterface } from '../../interfaces/steam-generation-form.interface';
import { BenchmarkInputsInterface } from '../../interfaces/benchmarkInputs.interface';
import { TabDirective } from "ngx-bootstrap/tabs/tab.directive";
import { SgaBoilerSchemeInterface } from "../../interfaces/sga-boiler-scheme.interface";
import { FormGroup } from "@angular/forms";
import { FormListComponent } from "../../components";


@Component({
  selector: 'app-sga-input-parameters',
  templateUrl: './sga-input-parameters.component.html',
  styleUrls: ['./sga-input-parameters.component.scss']
})
export class SgaInputParametersComponent implements OnInit {
  @Input() moduleGroupId: number;
  @Input() formGroup: TForm<InputParametersTFormInterface>;
  @Output() changeFuelType = new EventEmitter<SgaCalcCalorificReqInterface>();
  @Output() calculateEfficiency = new EventEmitter<SgaCalcBoilerEfficiencyReqInterface>();
  @Output() calculateWaterTreatment = new EventEmitter<SgaCalcWaterTreatmentReqInterface>();
  @Output() setTab: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild('fuel', {static: false}) fuelRef: FormListComponent;

  private readonly formValueGetter: TFormValueGetterInterface = this.formService
    .createFormValueGetter(this.formService.getInputParamsFg());
  structure: SgFormStructureInterface;
  sizingUnits$: Observable<{ [key: string]: {decimal: number, unit: string} }> = this.preferenceService.sizingUnitPreferencesUpdate
    .pipe(map(({list, updated}) => list.reduce((obj, item) => ({
      ...obj,
      [item.preference.name]: { decimal: item.preference.decimalPlaces, unit: item.preference.unitName }
    }), {})));

  constructor(
    private formService: SgaFormService,
    private preferenceService: PreferenceService,
    private unitsService: UnitsService
  ) {
    this.structure = JSON.parse(JSON.stringify(sgaFormStructure));
  }

  ngOnInit() {

   // this.setDefualtForPercentOfEffluentBoilerHouseWater();
    
  }

  get getBoilerSchemeState(): SgaBoilerSchemeInterface {
    const fg = this.formGroup.get('benchmarkInputs') as FormGroup;
    const {
      isEconomizerPresent = false,
      isBlowdownVesselPresent = false,
      isCoolingWaterUsed = false,
      isAutoTdsControlPResent = false,
      isFlashVesselPresent = false,
      isHeatExchangerPresent = false,
      pressurisedDeaerator = false,
      isDsiPresent = false,
    } = fg && fg.getRawValue();

    return {
      isEconomizerPresent, isBlowdownVesselPresent,
      isCoolingWaterUsed, isAutoTdsControlPResent, isFlashVesselPresent,
      isHeatExchangerPresent, pressurisedDeaerator, isDsiPresent };
  }

  get fuelCalorificUnit(): string {
    let fuelUnitId = this.formGroup.get('selectedUnits.fuelUnitSelected').value || 108;
    const calorificCtrl = this.formGroup.get('benchmarkInputs.fuelEnergyPerUnit');

    if (
      fuelUnitId === 355 /*DecaTherms*/ ||
      fuelUnitId === 354 /*mcf(1000ft3)*/ ||
      (this.fuelRef && this.fuelRef.item && this.fuelRef.item.value === 'O-Other')
    ) {
      fuelUnitId = 108; // kWh
    }

    const unit = this.unitsService.units.find(({id}) => id === fuelUnitId);

    if (
      (this.fuelRef && this.fuelRef.item && this.fuelRef.item.value === 'O-Other') ||
      (unit && (unit.type === 'BoilerHouseEnergy' || unit.type === 'BoilerHouseElectricalFuel'))
    ) {
      if (calorificCtrl && calorificCtrl.enabled) {
        calorificCtrl.disable({emitEvent: false});
      }
    } else if (calorificCtrl && calorificCtrl.disabled) {
      calorificCtrl.enable({emitEvent: false});
    }

    return unit && unit.units;
  }

  get co2EmissionUnit(): string {
    let fuelUnitId = this.formGroup.get('selectedUnits.fuelUnitSelected').value || 16;

    if (fuelUnitId === 355 /*DecaTherms*/ || fuelUnitId === 354 /*mcf(1000ft3)*/) {
      fuelUnitId = 16;
    }

    const unit = this.unitsService.units.find(({id}) => id === fuelUnitId);

    return unit && unit.units;
  }

  getInvalidBlock(structure): boolean {
    if (Array.isArray(structure)) {
      for (const el of structure) {
        if (el && typeof el === 'string') {
          const control = this.formGroup.get(`benchmarkInputs.${el}`);
          if (control && control.touched && control.invalid) {
            return true;
          }
        }
      }
    } else {
      let isInvalid = false;
      for (const key of Object.keys(structure)) {
        if (this.getInvalidBlock(structure[key])) {
          isInvalid = true;
        }
      }
      return isInvalid;
    }

    return false;
  }

  getValue(name: keyof BenchmarkInputsInterface): number {
    const control = this.formGroup.get(`benchmarkInputs.${name}`);
    return control && control.value;
  }

  toggleInput(enable: string | string[], disable: string | string[], patchValue?: any, isChange?: boolean) {
    if (isChange || isChange === undefined) {
      if (typeof enable === 'string') {
        const control = this.formGroup.get(`benchmarkInputs.${enable}`);
        if (control && control.disabled) { control.enable(); }
      } else if (Array.isArray(enable)) {
        for (const field of enable) {
          const control = this.formGroup.get(`benchmarkInputs.${field}`);
          if (control && control.disabled) { control.enable(); }
        }
      }
    }

    if (isChange === false || isChange === undefined) {
      if (typeof disable === 'string') {
        const control = this.formGroup.get(`benchmarkInputs.${disable}`);
        if (control && control.enabled) {
          control.disable();
          control.markAsUntouched({onlySelf: true});

          if (patchValue !== undefined) { control.patchValue(patchValue); }
        }
      } else if (Array.isArray(disable)) {
        for (const field of disable) {
          const control = this.formGroup.get(`benchmarkInputs.${field}`);
          if (control && control.enabled) {
            control.disable();
            control.markAsUntouched({onlySelf: true});

            if (patchValue !== undefined) { control.patchValue(patchValue); }
          }
        }
      }
    }
  }

  setFormValue(name: string | string[] | {[key: string]: any}, value?: any) {
    if (typeof name === 'string') {
      const control = this.formGroup.get(`benchmarkInputs.${name}`);
      if (control) { control.patchValue(value !== undefined ? value : null); }
    } else if (Array.isArray(name)) {
      for (const field of name) {
        const control = this.formGroup.get(`benchmarkInputs.${field}`);
        if (control) { control.patchValue(value !== undefined ? value : null); }
      }
    } else if (typeof name === 'object') {
      for (const key of Object.keys(name)) {
        const control = this.formGroup.get(`benchmarkInputs.${key}`);
        if (control) { control.patchValue(value !== undefined ? value : name[key]); }
      }
    }
  }

  getFuelTypeName(value: string): string {
    const firstLetter = value && value.charAt(0).toUpperCase();

    if (firstLetter && FuelTypesEnumerationLetter[firstLetter]) {
      return FuelTypesEnumerationLetter[firstLetter];
    }
  }

  fuelTypeHandle(data: FormListFuelTypeChangeInterface) {
    if (data && data.item && data.item.value) {
      const fuelTypeName = this.getFuelTypeName(data.item.value);
      const sPreference = this.preferenceService.sizingUnitPreferences && this.preferenceService.sizingUnitPreferences
        .find(({preference}) => preference.name === fuelTypeName);

      if (sPreference) {
        const emitData = this.formValueGetter({selectedUnits: ['energyUnitSelected', 'smallWeightUnitSelected']});
        const fuelUnitSelected = Number(sPreference.preference.value);

        const control = this.formGroup.get('selectedUnits.fuelUnitSelected');
        if (control && control.value !== fuelUnitSelected) {
          control.setValue(fuelUnitSelected);
        }
        this.changeFuelType.emit({
          energyUnitSelected: emitData.energyUnitSelected,
          smallWeightUnitSelected: emitData.smallWeightUnitSelected,
          inputFuelId: data.item.id as string,
          fuelUnitSelected,
        });
      }
    }
  }

  boilerEfficiencyHandle(value) {
    this.calculateEfficiency.emit({
      isEconomizerPresent: value,
      inputFuelId: this.formGroup.get('benchmarkInputs.inputFuelId').value,
    });
  }

  waterTreatmentHandle({selectedValue}) {
    this.calculateWaterTreatment.emit({
      waterTreatmentMethodId: selectedValue,
      tdsUnitSelected: this.formGroup.get('selectedUnits.tdsUnitSelected').value
    });
  }

  setBoilerParamsTab(tab: number | TabDirective, el?: HTMLElement) {
    let tabIndex: number;

    if (typeof tab === 'number') {
      tabIndex = tab;
      el && typeof el.scrollIntoView === 'function' && el.scrollIntoView({behavior: 'smooth'});
    } else if (tab && tab instanceof TabDirective) {
      if (tab.tabset && tab.tabset.tabs) {
        tabIndex = tab.tabset.tabs.indexOf(tab) + 1;
      }
    }

    if (tabIndex && tabIndex !== -1) {
      this.structure.boiler_house_parameters.panels.boiler.status = tabIndex === 1;
      this.structure.boiler_house_parameters.panels.tds_blowdown.status = tabIndex === 2;
      this.structure.boiler_house_parameters.panels.water_treatment.status = tabIndex === 3;
      this.structure.boiler_house_parameters.panels.feedwater_and_condensate.status = tabIndex === 4;
    }
  }

  clearFieldsError(fields: string | string[]) {
    const clearErrFn = (name: string) => {
      const control = this.formGroup.get(`benchmarkInputs.${name}`);

      if (control && control.errors) {
        control.setErrors(null);
      }
    }

    if (typeof fields === 'string') {
      clearErrFn(fields);
    }

    if (Array.isArray(fields)) {
      for (const field of fields) {
        clearErrFn(field);
      }
    }
  }

  //setDefualtForPercentOfEffluentBoilerHouseWater() {
  //  // If percentOfEffluentBoilerHouseWater is empty on page init, then default to 100%
  //  var current_percentOfEffluentBoilerHouseWater = this.getValue('percentOfEffluentBoilerHouseWater');
  //  if (!current_percentOfEffluentBoilerHouseWater || current_percentOfEffluentBoilerHouseWater === null) {
  //    this.setFormValue({ percentOfEffluentBoilerHouseWater: 100 });
  //  }
  //}
}
