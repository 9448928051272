import { FormGroup } from "@angular/forms";
import { UnitConvert, UnitsConverter, UnitsService } from "sizing-shared-lib";

export function convertToDefaultValueInPageUnits(propertyName: string, initValue: number, targetUnitRef: string, unitsService: UnitsService, form: FormGroup, side?: string) {

  // Convert defaults to active page units
  let unitsToConvert: UnitConvert[] = [];
  let initialUnitId: number;


  switch (propertyName) {
    case 'actualSecondaryPressureDrop': {
      initialUnitId = 39
      break;
    }
    case 'maximumCondensateTemperature':
    case 'inletTemperature':
    case 'outletTemperature':
    case 'hotwellTemperature':      {
      initialUnitId = 146
      break;
    }
  }

  let unitConvert = new UnitConvert();
  unitConvert.propertyName = propertyName;
  unitConvert.initialValue = initValue;
  unitConvert.initialUnitId = initialUnitId;
  unitConvert.targetUnitId = parseInt(targetUnitRef);
  unitConvert.convertedValue = null;
  unitsToConvert.push(unitConvert);

  let unitsConverter = new UnitsConverter();
  unitsConverter.unitsConverter = unitsToConvert;
  let convertedUnitValue;
  unitsService.unitsConverter(unitsConverter).subscribe((unitsConvertedData: UnitsConverter) => {

    if (!!unitsConvertedData && unitsConvertedData !== null) {

      convertedUnitValue = unitsConvertedData.unitsConverter.find(u => u.propertyName === propertyName).convertedValue;

      if (propertyName === 'maximumCondensateTemperature') {
        form.get(`${side}.${propertyName}`).patchValue((convertedUnitValue).toFixed(1), { emitEvent: false });
      }
      else if (propertyName === 'actualSecondaryPressureDrop') {
        form.get(`${side}.${propertyName}`).patchValue((convertedUnitValue).toFixed(1), { emitEvent: false });
      }
      else
        form.get(`processConditions.${propertyName}`).patchValue(convertedUnitValue.toFixed(), { emitEvent: true });
    }
  });
};
