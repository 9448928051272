import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, Subject } from "rxjs/Rx";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";

import { CleanSteamGeneratorFBProcessConditions } from "./CleanSteamGeneratorFBInput.model";
import { CleanSteamGeneratorFBOutput } from "./CleanSteamGeneratorFBOutput.model";
import { CleanSteamGeneratorFBInputValidation } from "./CleanSteamGeneratorFBInputValidation.model";
import { CleanSteamGeneratorFBProcessConditionsValidation } from "./CleanSteamGeneratorFBInputValidation.model";
import { CleanSteamGeneratorFBValidationMessage } from "./CleanSteamGeneratorFBInputValidation.model";
import { CleanSteamGeneratorFBPricing, CleanSteamGeneratorFBBOMPricingOutput } from "./CleanSteamGeneratorFBPricingOptions.model";
import { CleanSteamGeneratorFBPricingOutput } from "./CleanSteamGeneratorFBPricingOptions.model";
import { DocGen } from "./doc-gen.model";
import { CleanSteamGeneratorFBHalfPipeOutput } from './cleanSteamGeneratorFBHalfPipeOutput.model';
import { CleanSteamGeneratorFBDimensions, CleanSteamGeneratorFBDimensionsOutput } from './cleanSteamGeneratorFBDimensionsOptions.model';



@Injectable()
export class CleanSteamGeneratorFBService {

  constructor(private http: HttpClient) {

  }

  
  validateCleanSteamGeneratorFBInput(csgInput: CleanSteamGeneratorFBInputValidation): Observable<Array<CleanSteamGeneratorFBValidationMessage>> {
    return this.http.post<Array<CleanSteamGeneratorFBValidationMessage>>(`./Api/CSGFBValidation/ValidateCleanSteamGeneratorFBInput`, csgInput);
  }

  validateCleanSteamGeneratorFBProcessConditions(csgInput: CleanSteamGeneratorFBProcessConditionsValidation): Observable<Array<CleanSteamGeneratorFBValidationMessage>> {
    return this.http.post<Array<CleanSteamGeneratorFBValidationMessage>>(`./Api/CSGFBValidation/ValidateCleanSteamGeneratorProcessConditions`, csgInput);
  }

  getExcel(docGen: DocGen): Observable<boolean> {
    return this.http.post<boolean>(`./Api/CSGFBMini/GetExcelSpecSheet`, docGen);
  }





  sizeCleanSteamGeneratorFBMini(csgProcessConditions: CleanSteamGeneratorFBProcessConditions): Observable<Array<CleanSteamGeneratorFBOutput>> {
    return this.http.post<Array<CleanSteamGeneratorFBOutput>>(`./Api/CSGFBMini/SizeCleanSteamGenerator`, csgProcessConditions);
  }

  calculateTotalPriceFBMini(csgPricing: CleanSteamGeneratorFBPricing): Observable<CleanSteamGeneratorFBPricingOutput> {
    return this.http.post<CleanSteamGeneratorFBPricingOutput>(`./Api/CSGFBMini/CalculateTotalPrice`, csgPricing);
  }

  calculateBOMPriceFBMini(csgPricing: CleanSteamGeneratorFBPricing): Observable<CleanSteamGeneratorFBBOMPricingOutput> {
    return this.http.post<CleanSteamGeneratorFBBOMPricingOutput>(`./Api/CSGFBMini/CalculateBOMPrice`, csgPricing);
  }

  calculateDimensionsFBMini(csgSelectedOptions: CleanSteamGeneratorFBDimensions): Observable<CleanSteamGeneratorFBDimensionsOutput> {
    return this.http.post<CleanSteamGeneratorFBDimensionsOutput>(`./Api/CSGFBMini/CalculateDimensions`, csgSelectedOptions);
  }



  sizeCleanSteamGeneratorFBHalfPipe(csgProcessConditions: CleanSteamGeneratorFBProcessConditions): Observable<Array<CleanSteamGeneratorFBHalfPipeOutput>> {
    return this.http.post<Array<CleanSteamGeneratorFBHalfPipeOutput>>(`./Api/CSGFBHalfPipe/SizeCleanSteamGenerator`, csgProcessConditions);
  }

  calculateTotalPriceFBHalfPipe(csgPricing: CleanSteamGeneratorFBPricing): Observable<CleanSteamGeneratorFBPricingOutput> {
    return this.http.post<CleanSteamGeneratorFBPricingOutput>(`./Api/CSGFBHalfPipe/CalculateTotalPrice`, csgPricing);
  }

  calculateBOMPriceFBHalfPipe(csgPricing: CleanSteamGeneratorFBPricing): Observable<CleanSteamGeneratorFBBOMPricingOutput> {
    return this.http.post<CleanSteamGeneratorFBBOMPricingOutput>(`./Api/CSGFBHalfPipe/CalculateBOMPrice`, csgPricing);
  }

  calculateDimensionsFBHalfPipe(csgSelectedOptions: CleanSteamGeneratorFBDimensions): Observable<CleanSteamGeneratorFBDimensionsOutput> {
    return this.http.post<CleanSteamGeneratorFBDimensionsOutput>(`./Api/CSGFBHalfPipe/CalculateDimensions`, csgSelectedOptions);
  }

}
