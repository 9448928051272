import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-results-spec-sheet-details',
  templateUrl: './results-spec-sheet-details.component.html',
  styleUrls: ['./results-spec-sheet-details.component.scss']
})
export class ResultsSpecSheetDetailsComponent implements OnInit {
  @Input() specSheetForm: FormGroup;

  constructor() { }

  ngOnInit() {
  }

  onEnterKeydown(event) {
    //event.stopPropagation();
    document.getElementById(event.srcElement.id).blur();
  }
}
