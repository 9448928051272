import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from "rxjs/Rx";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import { EasiheatGen4Output } from "./../models/output.model";
import {
  EasiheatGen4ProcessConditions,
  EasiheatGen4InputValidation,
  EasiheatGen4FlowOrLoad,
  EasiheatGen4ProcessConditionsValidation ,
  EasiheatGen4ValidationMessage ,
  EasiheatGen4BackPressureValidation ,
  EasiheatGen4FlowRateValidation ,
  EasiheatGen4DiffTempValidation,
  EasiheatGen4ValidationErrorMessage
} from "./../models/inputValidation.model";
import { EHPricingSummaryOutput, EasiHeatGen4PricingInputs, EasiheatBOMPriceOutput, BOMItem, EasiHeatGen4PricingUnit, EHUnitPricingOutput } from "./../models/pricingOptions.model";
import { EHSizingDataInput } from "./../models/sizingInput";
import { DisplayGroup } from '../../../../sizing-shared-lib/src/lib/shared/translation/translation.model';
import { EnergySavingInput } from '../models/energySavingInput.model';
import { EnergySavingOutput } from '../models/energySavingOutput.model';
//import { DocGen } from "./doc-gen.model";


@Injectable()
export class EasiHeatGen4Service {

  constructor(private http: HttpClient) {

  }

  inletPressureCheck(inletPressureInput: EasiheatGen4InputValidation, htgcc: boolean): Observable<Array<EasiheatGen4ValidationMessage>> {
    if (!htgcc) {
      return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/InletPressureCheck`, inletPressureInput);
    }
    else {
      return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/InletPressureHTGCheck`, inletPressureInput);
    }
  }

  backPressureCheck(backPressureInput: EasiheatGen4BackPressureValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/BackPressureCheck`, backPressureInput);
  }

  motiveInletPressureCheck(inletPressureInput: EasiheatGen4BackPressureValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/MotiveInletPressureCheck`, inletPressureInput);
  }

  differentialTempCheck(diffPressureInput: EasiheatGen4DiffTempValidation, dhw: boolean): Observable<Array<EasiheatGen4ValidationMessage>> {
    if (dhw) {
      return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/DiffTempCheck`, diffPressureInput);
    }
    else {
      return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/DiffTempHTGCheck`, diffPressureInput);
    }
  }

  inletTemperatureCheck(inletTemperatureInput: EasiheatGen4InputValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/InletTempCheck`, inletTemperatureInput);
  }

  PRVInletPressureCheck(packageInletPressure: EasiheatGen4InputValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/PRVInletPressureCheck`, packageInletPressure);
  }

  outletTemperatureCheck(outletTemperatureInput: EasiheatGen4InputValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/OutletTempCheck`, outletTemperatureInput);
  }

  waterFlowRateCheck(outletTemperatureInput: EasiheatGen4FlowRateValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/InputFlowRateCheck`, outletTemperatureInput);
  }

  loadInputCheck(inletTemperatureInput: EasiheatGen4InputValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/InputLoadCheck`, inletTemperatureInput);
  }

  sizeEasiheat(ehSizingData: EHSizingDataInput): Observable<EasiheatGen4Output> {
    return this.http.post<EasiheatGen4Output>(`./Api/EasiHeatGen4/Calculate`, ehSizingData);
  }

  validateEasiheatInput(ehInput: EasiheatGen4InputValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/CSG/ValidateCleanSteamGeneratorInput`, ehInput);
  }

  validateEasiheatProcessConditions(ehInput: EasiheatGen4ProcessConditionsValidation): Observable<Array<EasiheatGen4ValidationMessage>> {
    return this.http.post<Array<EasiheatGen4ValidationMessage>>(`./Api/EasiHeatGen4Validation/ValidateEasiHeatProcessConditions`, ehInput);
  }

  calculateTotalPrice(ehPricing: EasiHeatGen4PricingInputs): Observable<EHUnitPricingOutput[]> {
    //calculateTotalPrice(): Observable < EHPricingSummaryOutput > {
    return this.http.post<EHUnitPricingOutput[]>(`./Api/EasiHeatGen4/Pricing`, ehPricing);
  }

  //calculateBOMPrice(ehPricing: EHPricing): Observable<EasiheatBOMPriceOutput> {
  //  return this.http.post<EasiheatBOMPriceOutput>(`./Api/CSG/CalculateBOMPrice`, ehPricing);
  //}

  calculateVolumetricFlow(ehFlowOrLoad: EasiheatGen4FlowOrLoad): Observable<number> {
    return this.http.post<number>(`./Api/EasiHeatGen4Validation/CalculateVolumetricFlow`, ehFlowOrLoad);
  }

  calculateLoad(ehFlowOrLoad: EasiheatGen4FlowOrLoad): Observable<number> {
    return this.http.post<number>(`./Api/EasiHeatGen4Validation/CalculateLoad`, ehFlowOrLoad);
  }

  calculateEnergySavings(esInput: EnergySavingInput): Observable<EnergySavingOutput> { 
    return this.http.post<EnergySavingOutput>(`./Api/EasiHeatGen4/CalculateSaving`, esInput)
}


  // getExcel(docGen: DocGen): Observable<boolean> {
  //   return this.http.post<boolean>(`./Api/CSG/GetExcelSpecSheet`, docGen);
  // }
  getTranslatedDisplayGroup(displayGroupName: string): Observable<DisplayGroup> {
    if (!!displayGroupName) {
      return this.http.get<DisplayGroup>(`./Api/Translation/GetTranslatedDisplayGroup/${displayGroupName}`)
        .map((displayGroupData: DisplayGroup) => {
          return displayGroupData;
        });
    }

    return Observable.of(null);
  }
}
