export class EnergySavingOutput {
  fuelCalorificValue: number;
  fuelCalorificValueUnit: number;
  fuelCO2eEmissions: number;
  fuelCO2eEmmissionUnit: number;
  easiHeatGen4EnergySavingUnits: EasiHeatGen4EnergySavingResultsUnit[];
}

export class EasiHeatGen4EnergySavingResultsUnit {
  unitType: string; 
  steamConsumptionReductionPercent: number; 
  steamMassSaved: number; 
  co2eEmmissionReductionPerYear: number;
  co2eEmmissionCostReductionPerYear: number;
  energySavedPerYear: number; 
  energyCostReductionPerYear: number;
  waterSavedPerYear: number;
  waterCostReductionPerYear: number; 
  totalEvcCostReductionPerYear: number; 
}
