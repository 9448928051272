export class ModulePreferenceModel {

  [key: string]: any;
  EHgen4_Commission: string;
  EHgen4_CurrencyConversion
  EHgen4_CVSplitRange: boolean;
  EHgen4_DeliveryCost: string;
  EHgen4_EHOptionNames: string;
  EHgen4_EHOptions: string;
  EHgen4_HEM10Discount: string;
  EHgen4_HEM6Discount: string;
  EHgen4_HEOverridenP251Markup: boolean;
  EHgen4_HEOverridenP251MarkupValue: string;
  EHgen4_HEAllowHighDesignPressure: boolean;
  EHgen4_HEPricingCurrency: string;
  EHgen4_HETS6Discount: string;
  EHgen4_HideHEmodelFromSizing: boolean;
  EHgen4_LandCostIncrease: string;
  EHgen4_Manufacturer: string;
  EHgen4_ManufacturerCurrency: string;
  EHgen4_MotiveInletPressureAvailable: boolean;
  EHgen4_PrvAllowed: boolean;
  EHgen4_SellingCurrency: string;
  EHgen4_SellingMarkup: string;
  EHgen4_SellSteamControl: boolean;
  EHgen4_YearOne: string;
  EHgen4_YearThree: string;
  EHgen4_YearTwo: string;
  EHgen4_HET10Discount: string;
  EHgen4_HideAllPricingUserPref: boolean;
  EHgen4_HideManufactureCostsUserPref: boolean;

  EHgen4_GasketCode: string;
  EHgen4_GlobalVariance: string;
}
