import { Message } from "sizing-shared-lib";
/**
 * The CleanSteamGeneratorDimensions model.
 */
export class CleanSteamGeneratorDimensions {  
  /**
   * Gets or sets the modelId.
   */
  modelId: number;

  /**
   * Gets or sets the manufacturerId.
   */
  manufacturerId: number;

  /**
   * Gets or sets the DimensionsOptions.
   */
  selectedOptions: CSGDimensionsOption[];

  lengthUnitId: number;

  weightUnitId: number;

}

export class CSGDimensionsOption {
  /**
   * Gets or sets the enumerationName.
   */
  enumerationName: string;

  /**
   * Gets or sets the selectedValue.
   */
  selectedValue: string;
}


export class CleanSteamGeneratorDimensionsOutput {

  /**
   * Gets or sets the Dimensions.
   */
  dimensions: Dimensions[];
}


export class Dimensions {

  /**
   * Gets or sets the id.
   */
  id: number;
  
  /**
    * Gets or sets the modelId.
    */
  modelId: number;

  /**
  * Gets or sets the manufacturerId.
  */
  manufacturerId: number;

  /**
    * Gets or sets the enumerationDefinitionValue.
    */
  enumerationDefinitionValue: string;

  /**
  * Gets or sets the optionSizeOrder.
  */
  optionSizeOrder: number;

  /**
  * Gets or sets the length.
  */
  length: number;

  /**
  * Gets or sets the width.
  */
  width: number;

  /**
  * Gets or sets the height.
  */
  height: number;

  /**
  * Gets or sets the clearanceForTubeExtraction.
  */
  clearanceForTubeExtraction: number;

  /**
  * Gets or sets the clearanceForDeaeratorExtraction.
  */
  clearanceForDeaeratorExtraction: number;

  /**
  * Gets or sets the dryWeight.
  */
  dryWeight: number;

  /**
  * Gets or sets the operationalWeight.
  */
  operationalWeight: number;

  /**
  * Gets or sets the maxWeight.
  */
  maxWeight: number;

  /**
    * Gets or sets the created.
    */
  created: Date;

  /**
    * Gets or sets the updated.
    */
  updated: Date;

  /**
  * Gets or sets the isDeleted.
  */
  isDeleted: boolean;

}




