import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-meter-details',
  templateUrl: './meter-details.component.html',
  styleUrls: ['./meter-details.component.scss']
})
export class MeterDetailsComponent {
  @Input() form: FormGroup;
  @Input() moduleGroupId: number;
  @Input() moduleName: string;
  @Output() productSelectionChanged = new EventEmitter<boolean>();

  public meters: any[];
  public oldCount: number = 0;


  updateSelection() {

    this.meters = this.form.get('meterDetails.selectedMeters').value;
    var newCount = this.meters.length;
    if (newCount != this.oldCount) {
      this.oldCount = newCount;
      this.productSelectionChanged.emit(true);
    }
  }

}
