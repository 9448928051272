/**
 * The CSG output data model.
 */
import { Message } from "sizing-shared-lib";

export class EasiheatGen4Output {
  easiHeatGen4Units: EasiHeatGen4Units[];
}

export class EasiHeatGen4Units { 

  nomenclature: string;
  subCooling: number;
  numberOfPlates: number;
  primaryPressureDrop: number;
  secondaryPressureDrop: number;
  displaySecondaryPressureDrop: string;
  steamCvSize: string;
  steamCvSizeAnsi: string;
  steamCvKv: string;
  steamCvCalculatedNoiseLevel: number;
  oversurfacing: number;
  primaryVelocity: number;
  secondaryVelocity: number;
  heOutletPressure: number;
  trimType: string;
  height: string;
  width: string;
  length: string;
  weight: string;
  condensateRemoval: string;
  grossMargin: number;
  hePressure: number;
  //cvTrimType: string;
  maxCondensateTemp: number;
  unitPrice: number;
  condensateValveDnSize: string;
  condensateValveAnsiSize: string;
  condensateValveTrim: string;
  condensateValveKv: string;
  hePrice: number;
  heWeight: number;
  heatExchangerPlateType: string;
  channelTypeSide1: string;
  channelTypeSide2: string;
  connectionStandard: string;
  numberOfChannelsSide1: number;
  numberOfChannelsSide2: number;
  energyMonitoringAvailable: false;
  bomUpdateInProgress: false;
  unitModel: string;
  dpPresent: false;
  steamMassFlow: number;
  htgCcInletPipeVeloctiyOver40ms: boolean;
  steamCvSplitRangeKv: string;
  steamValveCalculatedExitVelocityMetersPerSecond: number;
  steamCvInletDensity: number;
  steamCvOutletDensity: number;
  additionalMessage: string;
  heFrameType: string;
  epdmwGasketPressureCheck: boolean;

  //Values needed for Energy Saving Calculation APi call.
  cvOutletWaterEnthalpy: number;
  cvOutletEvaporationEnthalpy: number;
  balancePressureWaterEnthalpy: number;
  enthalpyOfPrimarySideInletSteam: number;

  /**
   * Gets or sets the modelSizingMessages.
   */
    modelSizingMessages: Message[];


  //temporary for Rob Dean
  steamCvRequiredKv: string;
  condensateValveRequiredKv: string;

  drawingFile: string;
  drawingOnlineLink: string;
  drawingOfflineLink: string;
  tiFile: string;
  tiOfflineLink: string;
  tiOnlineLink: string;
  nomenclatureChannelType: string;
}
