/**
 * The CSG output data model.
 */
import { Message } from "sizing-shared-lib";

import { CleanSteamGeneratorFBOutput } from './CleanSteamGeneratorFBOutput.model';

export class CleanSteamGeneratorFBHalfPipeOutput extends CleanSteamGeneratorFBOutput {

  /**
 * Gets or sets the numberOfPlates.
 */
  numberOfPlates: number;

  /**
   * Gets or sets the nomenclatureHPdataKV.
   */
  nomenclatureHPdataKV: string;

  /**
   * Gets or sets the nomenclatureHPdataCV. America's ASME support
   */
  nomenclatureHPdataCV: string;

  /**
   * Gets or sets the enthalpyOfWaterAtHotsideOutletTemperature.
   */
  enthalpyOfWaterAtHotsideOutletTemperature: number;
  dispalyEnthalpyOfWaterAtHotsideOutletTemperature: string;

  /**
   * Gets or sets the FeedwaterPressurisation. // 8.14.16 Feedwater Pressurisation
   */
  feedwaterPressurisation: number;

  /**
  * Gets or sets the feedWaterPressureInput convreted to BarG units. For UI to compare for message display rulling.
  */
  feedWaterPressureInputBarG: number;

  /**
  * Gets or sets the preheaterCondensateOutletConnection FBHP only data.
  */
  preheaterCondensateOutletConnection: string; //FBHP only

  /**
  * Gets or sets the debugDataGeneral.
  */
  debugDataGeneral: string;

  /**
  * Gets or sets the debugDataPackage.
  */
  debugDataPackage: string;

}
