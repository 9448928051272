import { CommonModule } from '@angular/common';
import { NgModule , ModuleWithProviders} from '@angular/core';
import { EasiHeatGen4Component } from './easiheat-gen4/easiheatgen4.component';
import { EasiheatProcessConditionsComponent } from './easiheat-process-conditions/easiheat-process-conditions.component'
import { EasiheatSizingResultsComponent } from './easiheat-sizing-results/easiheat-sizing-results.component'
import { SizingSharedLibModule } from 'sizing-shared-lib';
import { BlockUIModule } from 'ng-block-ui';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from 'sizing-shared-lib';
import { ChartsModule } from 'ng2-charts';

const providers = [];

@NgModule({
  declarations: [
    EasiHeatGen4Component,
    EasiheatProcessConditionsComponent,
    EasiheatSizingResultsComponent,    
  ],
  imports: [
    CommonModule,
    SizingSharedLibModule.forRoot(),
    
    BlockUIModule,
    SharedModule,
    ChartsModule,
  ],
  providers: providers,
  bootstrap: [EasiHeatGen4Component],
  exports: [EasiHeatGen4Component]
})
export class AppModule { }

@NgModule({})
export class EasiHeatGen4SharedModule{
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: providers
    }
  }
}
